import { Controller } from 'stimulus'
import Splide from '@splidejs/splide';

export default class extends Controller {
  connect() {
    const splide = new Splide(this.element, {
      type: 'loop',
      autoplay: true,
      perPage: 1,
      perMove: 1,
      pauseOnHover: false,
      interval: 5000,
    });

    splide.mount();
  }
}
