// import { Controller } from "@hotwired/stimulus"
import { Controller } from "stimulus"

// Connects to data-controller="share"
export default class extends Controller {
  static targets = ["result", "title", "body"];
  connect() {
    // console.log(this.resultTarget);
  }
  async share(e) {
    e.preventDefault();

    const shareData = {
      title: this.titleTarget.textContent,
      text: this.bodyTarget.textContent,
      url: this.data.get("urlValue")
    };
    console.log(shareData);
    try {
      await navigator.share(shareData);
      this.resultTarget.textContent = "Спасибо!";
    } catch(err) {
      // console.log(navigator.canShare(shareData));
      // console.log(err);
      this.resultTarget.textContent = `Error: ${err}`;
    }
  }
}
